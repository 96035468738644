// Loader.js
import React from 'react';
import './loader.css';  // We'll add custom CSS for the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="spinner"></div>
      <p className='loading-text'>Loading...</p>
    </div>
  );
};

export default Loader;
